.right_btns[data-v-7f00d240] {
  float: right;
  text-align: right;
  margin-bottom: 10px;
}
.left_search[data-v-7f00d240] {
  float: left;
  text-align: left;
}
.inconStyle[data-v-7f00d240] {
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 18px;
  line-height: 24px;
}
.pagination[data-v-7f00d240] {
  margin-top: 15px;
}
.content_data[data-v-7f00d240] {
  background-color: #d8ecff;
  margin-bottom: 10px;
}
.each[data-v-7f00d240] {
  width: 15%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}
.each span[data-v-7f00d240] {
  font-size: 13px;
  font-weight: 600;
  color: #000;
}
.each a[data-v-7f00d240] {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: #141720;
          text-decoration-color: #141720;
}
.icon_bg[data-v-7f00d240] {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 35px;
}
.icon_bg .iconfont[data-v-7f00d240] {
  font-size: 18px;
  color: #17a2b8;
}